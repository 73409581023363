<template>
	<!-- 新增文章专栏 -->
	<div class="container">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/policyCompilation' }"><el-button type="primary" size="mini">返回</el-button></el-breadcrumb-item>
			<el-breadcrumb-item><el-button type="text" size="mini" style="color: #555;font-size: 14px;">新增文章专栏</el-button></el-breadcrumb-item>
		</el-breadcrumb>

		<el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item label="专栏标题" prop="title">
				<el-input style="width: 180px;" maxlength="6" placeholder="请输入专栏标题" v-model="ruleForm.title"></el-input>
				<span style="margin-left: 15px;">( 注：标题少于6个汉字 )</span>
			</el-form-item>

			<el-form-item label="专题描述" prop="description">
				<el-input style="width: 350px;" type="textarea" :rows="6" maxlength="1000" show-word-limit placeholder="请输入专栏标题" v-model="ruleForm.description"></el-input>
			</el-form-item>

			<el-form-item label="专栏排序" prop="display_order">
				<el-input style="width: 100px;" maxlength="5" v-model="ruleForm.display_order"></el-input>
				<span style="margin-left: 15px;">（ 注：专栏的排序,数字越大越靠前 )</span>
			</el-form-item>
			<el-form-item><el-button type="primary" @click="submitForm('ruleForm')">确定</el-button></el-form-item>
		</el-form>
	</div>
</template>
<script>
export default {
	data() {
		return {
			organ_id: '',
			id: '',
			ruleForm: {
				title: '',
				description: '',
				display_order: ''
			},
			rules: {
				title: [
					{
						required: true,
						message: '请输入专栏标题',
						trigger: 'blur'
					}
				],
				description: [
					{
						required: true,
						message: '请输入专栏描述',
						trigger: 'blur'
					}
				],
				display_order: [
					{
						required: true,
						message: '请输入专栏排序',
						trigger: 'blur'
					}
				]
			}
		};
	},
	mounted() {
		this.organ_id = sessionStorage.organizationId;
		this.id = this.$route.query.id;
		if (typeof this.id != 'undefined') {
			this.getdetailsData();
		}
	},
	methods: {
		getdetailsData() {
			this.$http
				.get(this.URL.adminUrl.articleActiveUpdata + this.id + '/edit', {
					params: {
						from: 'manager',
						organ_id: this.organ_id
					}
				})
				.then(res => {
					console.log(res.data);
					this.ruleForm = res.data.data;
				});
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$http
						.post(this.URL.adminUrl.article_types, {
							id: this.id,
							from: 'manager',
							organ_id: this.organ_id,
							display_order: this.ruleForm.display_order,
							description: this.ruleForm.description,
							title: this.ruleForm.title
						})
						.then(res => {
							if (res.data.status == 'SUCCESS') {
								if (typeof this.id != 'undefined') {
									var wsj = '修改成功';
								} else {
									var wsj = '新增成功';
								}
								this.$alert(wsj, {
									callback: () => {
										this.$router.push('/policyCompilation');
									}
								});
							}
						});
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style scoped>
.policyCompilationTitle {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	font-size: 18px;
	height: 40px;
}
.navbar {
	margin-bottom: 30px;
	padding: 15px;
	border-bottom: 1px solid #ccc;
}
</style>
